import React, { useContext } from 'react';
import classnames from 'classnames';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { addLocalePrefix } from './Link'
import Section from './Section';
import LocaleContext from './LocaleContext';

import navigationData from '../data/navigation';

import './FeaturedPlatformPagesSection.scss';

const FeaturedPlatformPagesSection = (props) => {
  const locale = useContext(LocaleContext)
  const title = props.title || "Manage people, payroll and compliance, in one platform"
  const navLinks = navigationData[locale.value] || []
  const pages = navLinks
    .find(item => item.label === 'Platform')
    .children
    .map(item => {
      const link = (item.localePrefix)
        ? addLocalePrefix(item.href)
        : item.href

      return {
        link,
        label: item.label,
        image: item.image,
        description: item.desc
      }
    })

  return (
    <Section className="FeaturedPlatformPagesSection isolated-section text-center py-6">
      <Container>
        <h2 className="mt-4 mx-auto">{title}</h2>
      </Container>

      <div className="FeaturedPlatformPagesSection__itemsWrapper">
        <Container>
          <div className="FeaturedPlatformPagesSection__items d-flex flex-wrap justify-content-center">
            {pages.map((page, index) => (
              <div className="FeaturedPlatformPagesSection__item" key={index}>
                <a href={page.link} className={classnames(
                  "d-flex flex-column",
                  { "mt-xl-4": index % 2 !== 0 }
                )}>
                  <div className={classnames(
                    "FeaturedPlatformPagesSection__itemImg mb-3",
                    {
                      "order-xl-1": index % 2 === 0,
                      "order-xl-10 mb-xl-0 mt-xl-auto": index % 2 !== 0
                    }
                  )}>
                    {page.image && <img className="mx-auto" src={page.image} /> }
                  </div>
                  <div className="FeaturedPlatformPagesSection__itemName mb-3 order-2">{page.label}</div>
                  <p className="FeaturedPlatformPagesSection__itemDesc mb-3 order-3">{page.description}</p>
                  <div className="FeaturedPlatformPagesSection__itemLearnMore mb-3 order-4">Learn more <FontAwesomeIcon icon={faArrowRight} className="ml-2"/></div>
                </a>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </Section>
  )
}

export default FeaturedPlatformPagesSection