import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import './ViewMoreButton.scss'

import dots from '../images/button/More-Dots.svg'
import arrow from '../images/button/More-Arrow.svg'

export const ViewMoreButton = ({
  onClick,
  ...rest
}) => (
  <Container>
    <div className="view-more">
      <div className="view-more__button-wrapper" {...rest}>
        <Button color="electric-blue" className="mt-2" onClick={onClick}>
          Load More
        </Button>
      </div>
    </div>
  </Container>
)

export default ViewMoreButton;
