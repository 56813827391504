import React, { useState, useEffect, useContext } from 'react'
import Sticky from 'react-stickynode'
import classnames from 'classnames'
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import ResourceCard from './ResourceCard'
import ViewMoreButton from './ViewMoreButton'

import LocaleContext from './LocaleContext'

import './ResourcesNew.scss'

import resourcesImage from '../images/hero/resources.jpg'
import covidImage from '../images/hero/COVID-19-Resource-Graphic.png'

/**
 * Sort all resources items based in common properties
 *
 */
export const sortResources = (items = []) =>
  items.slice().sort((a, b) => {
    if (a.isNew && !b.isNew) {
      return -1
    } else if (!a.isNew && b.isNew) {
      return 1
    } else if (Date.parse(a.created) > Date.parse(b.created)) {
      return -1
    } else if (Date.parse(a.created) < Date.parse(b.created)) {
      return 1
    } else if ((a.order || 0) < (b.order || 0)) {
      return -1
    } else if ((a.order || 0) > (b.order || 0)) {
      return 1
    } else {
      return 0
    }
  })

const DEFAULT_RESOURCES_TO_SHOW = 18
const ADD_RESOURCES_TO_SHOW = 12

export default ({ settings = null, theme = null, navSticky = true }) => {
  const [resourcesToShow, setResourcesToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [activeTab, setActiveTab] = useState(
    settings && settings[0] && settings[0].hash
  )

  useEffect(() => {
    if (window.location.hash) {
      let windowHash = window.location.hash.substring(1)

      if (settings.some(({ hash }) => hash === windowHash))
        setActiveTab(windowHash)
        if(windowHash=="covid") {
          document.querySelector(".Hero__background").style.backgroundImage = "url("+covidImage+")";
        } else {
          document.querySelector(".Hero__background").style.backgroundImage = "url("+resourcesImage+")";
        }
    }
  }, [])

  const toggle = hash => {
    if (hash && activeTab !== hash) {
      if (hash !== window.location.hash) window.location.hash = hash

      setResourcesToShow(DEFAULT_RESOURCES_TO_SHOW)
      setActiveTab(hash)
      if(hash=="covid") {
        document.querySelector(".Hero__background").style.backgroundImage = "url("+covidImage+")";
      } else {
        document.querySelector(".Hero__background").style.backgroundImage = "url("+resourcesImage+")";
      }
    }
  }

  const locale = useContext(LocaleContext)

  return (
    <div className="ResourcesNew">
      <TabContent activeTab={activeTab}>
        {settings.map(({ hash, items }) => {
          const itemsToShow = items && items.slice(0, resourcesToShow)

          return (
            <TabPane tabId={hash} key={hash}>
              <Container>
                <Row>
                  {itemsToShow &&
                    itemsToShow.map((item, index) => (
                      <Col key={index} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
              </Container>
              {items.length > itemsToShow.length && (
                <div className="mt-3">
                  <ViewMoreButton
                    onClick={() =>
                      setResourcesToShow(
                        resourcesToShow + ADD_RESOURCES_TO_SHOW
                      )
                    }
                  />
                </div>
              )}
            </TabPane>
          )
        })}
      </TabContent>
    </div>
  )
}
