import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

import Layout from '../../../components/Layout'
import Section from '../../../components/Section'
import Hero from '../../../components/Hero'
import FeaturedPlatformPagesSection from '../../../components/FeaturedPlatformPagesSection'
import LatestResourcesSection from '../../../components/LatestResourcesSection'
import FaqSection from '../../../components/FaqSection'
import CustomerStorySliderSection from '../../../components/CustomerStorySliderSection'
import BookDemoSection from '../../../components/BookDemoSection'
import { bookADemoLinkSwitch } from '../../../components/lib/bookADemoLinkSwitch'

import home2022_1 from '../../../images/features2023/hiring-and-onboarding/Feature_Images/23-129_MyHR_Onboarding_Platform_Page_Onboarding-software.png'
import home2022_2 from '../../../images/features2023/hiring-and-onboarding/Feature_Images/23-129_MyHR_Onboarding_Platform_Page_Onboarding-workflows-AU.png'
import home2022_3 from '../../../images/features2023/hiring-and-onboarding/Feature_Images/23-129_MyHR_Onboarding_Platform_Page_Employment-Contracts-AU.png'
import home2022_4 from '../../../images/features2023/hiring-and-onboarding/Feature_Images/23-129_MyHR_Onboarding_Platform_Page_Custom-Tasks-AU.png'
import home2022_5 from '../../../images/features2023/hiring-and-onboarding/Feature_Images/23-129_MyHR_Onboarding_Platform_Page_Recruitment-support.png'
import bannerIcon1 from '../../../images/features2023/hiring-and-onboarding/Top_Banner_Icons/23-129_MyHR_Onboarding_Platform_Page_Streamline-hiring.png'
import bannerIcon2 from '../../../images/features2023/hiring-and-onboarding/Top_Banner_Icons/23-129_MyHR_Onboarding_Platform_Page_Paperless.png'
import bannerIcon3 from '../../../images/features2023/hiring-and-onboarding/Top_Banner_Icons/23-129_MyHR_Onboarding_Platform_Page_Employee-experience.png'
import customerStories from '../../../data/customerStories'

import '../../platform.scss'

const PAGE_CONFIG = {
  locale: "en-AU",
  meta: {
    title: "Our Platform | Hiring & Onboarding",
    description: "Expert advice on employee hiring & onboarding process. Smart recruitment software. The best payroll & onboarding tool for Australia businesses."
  },
  tourLink: "https://app.storylane.io/share/khkknhixy6ug"
}

export default () => {
  const demoLink = bookADemoLinkSwitch(PAGE_CONFIG.locale);

  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  return (
    <Layout
      className="PlatformPage"
      title={PAGE_CONFIG.meta.title}
      description={PAGE_CONFIG.meta.description}
      locale={PAGE_CONFIG.locale}
    >
      <Hero variant="hiring-and-onboarding">
        <h1 className="restrict-width">Employee onboarding made easy.</h1>
        <p>
          Set your new hires up for success with automated workflows, reminders and streamlined documentation.
        </p>
        <Button
          color="cyan"
          href={demoLink}
          className="mr-3"
        >
          Book a demo
        </Button>
        <Button
          color="outline-cyan"
          href={PAGE_CONFIG.tourLink}
          target="_blank"
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
          Take the tour
        </Button>
      </Hero>

      <Section className="BannerIconSection">
        <Container>
          <Row className="">
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon1} />
              <p>Streamline hiring and onboarding with automated workflows and tasks</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon2} />
              <p>Reduce admin and stay organised with 100% paperless onboarding</p>
            </Col>
            <Col xs={4} md={4} lg={4} className="bannerIcon new">
              <img src={bannerIcon3} />
              <p>Deliver an unforgettable employee experience from day one</p>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="Content greyBack">
        <Container>

          <Row className="mt-5 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>ONBOARDING  SOFTWARE</h4>
              <h3>Fully paperless onboarding</h3>
              <p>
                Hit performance goals sooner with digital onboarding. Build customised onboarding plans using our best-practice template to ensure you 
                deliver a seamless onboarding experience. You’ll reduce admin and streamline processes, with all documents in one easy to use platform. 
              </p>
              <ul>
                <li>Onboarding strategy advice  </li>
                <li>Customised onboarding plans</li>
                <li>Template library</li>
                <li>LMS integration*</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_1} className="make-smaller" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_2} className="make-smaller" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>AUTOMATED WORKFLOWS</h4>
              <h3>Automate your onboarding processes</h3>
              <p>
                Empower employees fast with effective digital induction and training. Your new hire processes and workflows will be automated, allowing you 
                to bring new employees on board quickly and get them up to speed. Create customised onboarding plans, define and assign tasks, link company 
                documents, and monitor progress.
              </p>
              <ul>
                <li>Automated induction</li>
                <li>Employee self-service</li>
                <li>Assign and track assets</li>
                <li>Define access and permissions</li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>EMPLOYMENT CONTRACTS</h4>
              <h3>Get important documents signed with ease</h3>
              <p>
                Build a solid foundation for each employment relationship. Hire fast and confidently with digital policy & document acceptance. Get customised 
                employment contracts ready for digital signature, and keep all your employment documents up-to-date with on-call support. All key documents 
                are stored in one place, ready to sign and action.
              </p>
              <ul>
                <li>Employment contracts</li>
                <li>Digital doc acceptance</li>
                <li>Compliance</li>
                <li>e-Signature contracts</li>
                <li>Help navigating Modern Awards</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_3} className="" />
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6">
              <img src={home2022_4} className="make-smaller" />
            </Col>
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 pr-md-5 d-flex flex-column justify-content-center">
              <h4>CUSTOM TASKS</h4>
              <h3>Create customised onboarding experiences</h3>
              <p>
                Fully customisable onboarding templates for individual roles, teams, or offices. Determine key tasks and completion dates for each new hire a
                nd keep track of progress. Identify skills or support needs with detailed onboarding reports. Assign roles and keep all relevant people involved, 
                with automatic reminders for key stakeholders. 
              </p>
              <ul>
                <li>Training tracking</li>
                <li>Custom reminders</li>
                <li>Ensure legislative and organisational compliance</li>
              </ul>
            </Col>
          </Row>

          <Row className="mt-4 sectionLink">
            <Col md={12} lg={6} className="py-6 px-4 pl-md-6 d-flex flex-column justify-content-center order-2 order-md-1">
              <h4>RECRUITMENT SUPPORT</h4>
              <h3>Hire the best talent every time</h3>
              <p>
                Receive customised recruitment support, workforce planning, and applicant screening guidance targeted to your needs. We offer a comprehensive job 
                description database, as well as fully-customisable position descriptions. Our on-call HR experts will write your job ads, prepare interview 
                questions, and help you set market pay rates. Integrate with best-in-class ATS providers for a faster hiring process.
              </p>
              <ul>
                <li>JobAdder ATS integration</li>
                <li>Job descriptions</li>
                <li>Remuneration benchmarking </li>
                <li>Customised interview & applicant tools</li>
                <li>Recruitment strategy</li>
              </ul>
            </Col>
            <Col md={12} lg={6} className="pb-0 pb-md-6 px-5 px-md-6 pt-6 order-1 order-md-2">
              <img src={home2022_5} className="" />
            </Col>
          </Row>

        </Container>
      </Section>

      <CustomerStorySliderSection
        stories={[
          {
            ...customerStories['hse'],
            "moreStoriesButton": true
          }
        ]}
      />

      <FeaturedPlatformPagesSection title="Check out our other features"/>    

      <LatestResourcesSection />

      <FaqSection 
        title="Hiring and onboarding FAQs"
        items={[
          {
            question: "What is an employee onboarding process?",
            answer: (
              <>
                <p>
                  Onboarding is a structured process to provide a new employee with all the tools, resources, and knowledge they need to become a successful and productive team member as quickly as possible, while at the same time integrating them with the company and its culture.
                </p>
                <p>
                  <a href="https://blog.myhr.works/en-au/why-employee-onboarding-is-so-important">Read more about the onboarding process</a>
                </p>
              </>
            )
          },
          {
            question: "How can I automate employee onboarding?",
            answer: (
              <>
                <p>
                  Automating onboarding is all about digitising manual processes, such as training and tracking, as well as all your related employment documentation, so every new employee gets the best possible start in their role.
                </p>
                <p>
                  Sign up to MyHR and make use of our best-practice onboarding template and automated reminders to cover all your employer obligations and ensure your employees are trained and equipped to perform.
                </p>
                <p>
                  <a href="https://blog.myhr.works/en-au/complete-employee-onboarding-checklist">Complete onboarding checklist</a>
                </p>
              </>
            )
          },
          {
            question: "How much can you save with HR onboarding software?",
            answer: (
              <p>
                Onboarding can be a time-consuming process, so the efficiencies that software and automation bring can result in a significant cost saving for any business. You’ll also guarantee all new hires complete the thorough induction and training they need to hit the ground running.
              </p>
            )
          },
          {
            question: "How does a job description help in effective recruitment?",
            answer: (
              <>
                <p>
                  Creating an accurate, up-to-date job description gives you a clear view of the work the business needs done to meet its objectives, and what the ideal candidate would bring to the role. Job descriptions also give prospective hires an accurate definition of what they will be hired to do.
                </p>
                <p>
                  <a href="https://blog.myhr.works/en-au/writing-a-position-description">Read more job description tips</a>
                </p>
              </>
            )
          },
          {
            question: "How do I simplify my business’ recruitment process?",
            answer: (
              <>
                <p>
                  Make sure your company's strategy and mission are up-to-date, and that your employment documents, e.g. job descriptions, are current so that you know your objectives and how individual roles contribute to them. Always be on the lookout for talent, so when it comes time to recruit, you have some prime candidates up your sleeve. 
                </p>
                <p>
                  <a href="https://blog.myhr.works/en-au/why-effective-recruitment-is-important">Read more about effective recruitment</a>
                </p>
              </>
            )
          }
        ]}
      />

      <BookDemoSection
        variant="compact"
        text={
          <p>
            Secure the best talent every time and set them up for success.
          </p>
        }
        showTrialButton={false}
        extraButtons={
          <Button
            color="outline-cyan"
            href={PAGE_CONFIG.tourLink}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPlay} className="mr-2 ml-0" />
            Take the tour
          </Button>
        }
      />

    </Layout>
  )
}
