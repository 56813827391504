import React, { useState, useEffect, useContext } from 'react'
import Sticky from 'react-stickynode'
import classnames from 'classnames'
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

import ResourceCard from './ResourceCard'
import ViewMoreButton from './ViewMoreButton'

import LocaleContext from './LocaleContext'

import './Resources.scss'
import './ResourcesNew.scss'

import resourcesImage from '../images/hero/resources.jpg'
import covidImage from '../images/hero/COVID-19-Resource-Graphic.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faBookOpen } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

/**
 * Sort all resources items based in common properties
 *
 */
  export const sortResources = (items = []) =>
    items.sort((a, b) => {
      // Convert to Date objects to compare and sort by newest first
      const dateA = a.created ? new Date(a.created) : new Date(0);
      const dateB = b.created ? new Date(b.created) : new Date(0);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
    
      // If dates are same, sort alphabetically by title
      return (a.title || "").localeCompare(b.title || "");
    });

const DEFAULT_RESOURCES_TO_SHOW = 3
const ADD_RESOURCES_TO_SHOW = 3

export default ({ settings = null, theme = null, navSticky = true, heroItem = null }) => {
  const [resourcesToShow, setResourcesToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW + 9
  )
  const [cat1CountToShow, setCat1CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat2CountToShow, setCat2CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat3CountToShow, setCat3CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat4CountToShow, setCat4CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat5CountToShow, setCat5CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat6CountToShow, setCat6CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat7CountToShow, setCat7CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )
  const [cat8CountToShow, setCat8CountToShow] = useState(
    DEFAULT_RESOURCES_TO_SHOW
  )

  const [activeTab, setActiveTab] = useState(
    settings && settings[0] && settings[0].hash
  )

  useEffect(() => {
    if (window.location.hash) {
      let windowHash = window.location.hash.substring(1)

      if (settings.some(({ hash }) => hash === windowHash))
        setActiveTab(windowHash)
        
        if(windowHash=="live") {
          document.querySelector(".FeaturedSection").style.display = 'block';
        } else {
          document.querySelector(".FeaturedSection").style.display = 'none';
        }
    }
  }, [])

  const toggle = hash => {
    if (hash && activeTab !== hash) {
      if (hash !== window.location.hash) window.location.hash = hash

      setResourcesToShow(DEFAULT_RESOURCES_TO_SHOW + 9)
      setActiveTab(hash)
      
      if(hash=="live") {
        document.querySelector(".FeaturedSection").style.display = 'block';
      } else {
        document.querySelector(".FeaturedSection").style.display = 'none';
      }
    }
  }

  const locale = useContext(LocaleContext)
  
  const clickCard = (event) => {
    event.preventDefault();
    var el = event.currentTarget;
    window.location.href = el.getAttribute("data-href")
  }

  return (
    <div className="ResourcesNew Resources">
      <Sticky enabled={navSticky} activeClass="sticky" top={60}>
        <Container>
          <Nav className="Resources__nav justify-content-center">
            {settings.map(({ label, hash }) => (
              <NavItem key={hash} className={hash}>
                <NavLink
                  className={classnames({ active: activeTab === hash })}
                  id={hash}
                  onClick={() => {
                    toggle(hash)
                  }}
                >
                  <span>
                  {hash=="blogs" && <FontAwesomeIcon icon={faBookOpen} />}
                  {hash=="guides" && <FontAwesomeIcon icon={faDownload} />}
                  {hash=="whitepapers" && <FontAwesomeIcon icon={faBook} />}
                  {hash=="live" && <FontAwesomeIcon icon={faPlay} />}
                  {hash=="faqs" && <FontAwesomeIcon icon={faInfoCircle} />}
                  {hash=="all" && <FontAwesomeIcon icon={faGlobe} />}
                  &nbsp;</span>
                  {label}
                </NavLink>
              </NavItem>
            ))}
            <li className="Resources__nav__search">
              <form
                type="POST"
                action="https://blog.myhr.works/hs-search-results"
              >
                <input
                  type="text"
                  name="term"
                  className="Resources__nav__search__input"
                  placeholder="Search blog posts"
                />
                <input type="hidden" name="type" value="SITE_PAGE" />
                <input type="hidden" name="type" value="LANDING_PAGE" />
                <input type="hidden" name="type" value="BLOG_POST" />
                <input type="hidden" name="type" value="LISTING_PAGE" />
                <input type="hidden" name="type" value="KNOWLEDGE_ARTICLE" />
                <input type="hidden" name="language" value={locale.value} />
              </form>
            </li>
          </Nav>
        </Container>
      </Sticky>
      <Container>
        <div className="Resources__nav__search__wide">
          <form type="POST" action="https://blog.myhr.works/hs-search-results">
            <input
              type="text"
              name="term"
              className="Resources__nav__search__wide__input"
              placeholder="Search blog posts"
            />
            <input type="hidden" name="type" value="SITE_PAGE" />
            <input type="hidden" name="type" value="LANDING_PAGE" />
            <input type="hidden" name="type" value="BLOG_POST" />
            <input type="hidden" name="type" value="LISTING_PAGE" />
            <input type="hidden" name="type" value="KNOWLEDGE_ARTICLE" />
            <input type="hidden" name="language" value={locale.value} />
          </form>
        </div>
      </Container>
      <Container>
        {heroItem && heroItem.heroItem && 
        <div className="FeaturedSection Event">
          <Row>
            <Col sm={12} md={6} lg={6} className="card-left">
              <div className="card d-flex flex-column h-100" onClick={clickCard} data-href={`https://www.myhr.works/${locale.countryCodeLower}/landing/${heroItem.heroItem.slug}`}>
                <div className="type">
                  <div>
                    <p><FontAwesomeIcon icon={faCalendar} /> Upcoming Event</p>
                    <h3>{heroItem.heroItem.communityHubTitle}</h3>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div dangerouslySetInnerHTML={{ __html: heroItem.heroItem.communityHubDescription && heroItem.heroItem.communityHubDescription.childMarkdownRemark && heroItem.heroItem.communityHubDescription.childMarkdownRemark.html }} />
                  <a className="sectionLinkArrow" href={`https://www.myhr.works/au/landing/${heroItem.heroItem.slug}`}>Register now&nbsp; <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6} className="">
              <div className="card d-flex flex-column justify-content-between h-100" onClick={clickCard} data-href={`https://www.myhr.works/${locale.countryCodeLower}/landing/${heroItem.heroItem.slug}`}>
                <img src={heroItem.heroItem.communityHubThumbnail && heroItem.heroItem.communityHubThumbnail.file && heroItem.heroItem.communityHubThumbnail.file.url} className="thumbnail" />
              </div>
            </Col>
          </Row>
        </div>}
        {(!heroItem || !heroItem.heroItem) && <div className="FeaturedSection hidden"></div>}
      </Container>
      <TabContent activeTab={activeTab}>
      
        {settings.map(({ hash, items }) => {
          const itemsToShow = items && items.slice(0, resourcesToShow)
          
          const allCat1 = items.filter(item => (item.category && item.category.includes("Recruitment")) || (item.category && item.category.includes("Onboarding")))
          const cat1ToShow = allCat1 && allCat1.slice(0, cat1CountToShow)
          
          const allCat2 = items.filter(item => (item.category && item.category.includes("Performance reviews")))
          const cat2ToShow = allCat2 && allCat2.slice(0, cat2CountToShow)
          
          const allCat3 = items.filter(item => (item.category && item.category.includes("Disciplinaries & Issues Management")) || (item.category && item.category.includes("Employee Conflict")) || (item.category && item.category.includes("Termination")))
          const cat3ToShow = allCat3 && allCat3.slice(0, cat3CountToShow)
          
          const allCat4 = items.filter(item => (item.category && item.category.includes("Employee retention")) || (item.category && item.category.includes("Workplace Wellbeing")) || (item.category && item.category.includes("Remuneration")))
          const cat4ToShow = allCat4 && allCat4.slice(0, cat4CountToShow)
          
          const allCat5 = items.filter(item => (item.category && item.category.includes("Restructuring & Redundancy")))
          const cat5ToShow = allCat5 && allCat5.slice(0, cat5CountToShow)
          
          const allCat6 = items.filter(item => (item.category && item.category.includes("Payroll & leave management")))
          const cat6ToShow = allCat6 && allCat6.slice(0, cat6CountToShow)
          
          const allCat7 = items.filter(item => (item.category && item.category.includes("HR Compliance")) || (item.category && item.category.includes("Employment law updates")))
          const cat7ToShow = allCat7 && allCat7.slice(0, cat7CountToShow)
          
          const allCat8 = items.filter(item => (item.category && item.category.includes("Product updates")) || (item.category && item.category.includes("Opinion hour")) || (item.category && item.category.includes("Business insight")))
          const cat8ToShow = allCat8 && allCat8.slice(0, cat8CountToShow)

          return (
            <TabPane tabId={hash} key={hash}>
              
              {hash=='blogs' && cat1ToShow && cat1ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>Hiring & Onboarding</h3>
                  <p class="intro">The ins and outs of finding the best talent and getting them up to speed.</p>
                </div>
                <Row className="blog-section-items">
                  {cat1ToShow &&
                    cat1ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat1.length > cat1ToShow.length && (
                  <a
                    onClick={() =>
                      setCat1CountToShow(
                        cat1CountToShow + allCat1.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                  
                </div>
              </Container>}
              
              {hash=='blogs' && cat2ToShow && cat2ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>Performance Reviews</h3>
                  <p class="intro">How to bring out the best in your people, strengthen the employment relationship and boost achievement.</p>
                </div>
                <Row className="blog-section-items">
                  {cat2ToShow &&
                    cat2ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat2.length > cat2ToShow.length && (
                  <a
                    onClick={() =>
                      setCat2CountToShow(
                        cat2CountToShow + allCat2.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
                
              </Container>}
              
              {hash=='blogs' && cat3ToShow && cat3ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>Disciplinary & Issues Management</h3>
                  <p class="intro">Tips for dealing with difficult employees, and managing end of employment.</p>
                </div>
                <Row className="blog-section-items">
                  {cat3ToShow &&
                    cat3ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat3.length > cat3ToShow.length && (
                  <a
                    onClick={() =>
                      setCat3CountToShow(
                        cat3CountToShow + allCat3.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
                
              </Container>}
              
              {hash=='blogs' && cat4ToShow && cat4ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>Remuneration & Employee Retention</h3>
                  <p class="intro">How to motivate your employees and increase productivity.</p>
                </div>
                <Row className="blog-section-items">
                  {cat4ToShow &&
                    cat4ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat4.length > cat4ToShow.length && (
                  <a
                    onClick={() =>
                      setCat4CountToShow(
                        cat4CountToShow + allCat4.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
                
              </Container>}
              
              {hash=='blogs' && cat5ToShow && cat5ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>Restructuring & Redundancy</h3>
                  <p class="intro">Best practice advice on how to make changes to your business.</p>
                </div>
                <Row className="blog-section-items">
                  {cat5ToShow &&
                    cat5ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat5.length > cat5ToShow.length && (
                  <a
                    onClick={() =>
                      setCat5CountToShow(
                        cat5CountToShow + allCat5.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
              </Container>}
              
              {hash=='blogs' && cat6ToShow && cat6ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>Payroll & Leave Management</h3>
                  <p class="intro">A good look at managing leave and payroll  and ways your business can make both processes easier and more accurate.</p>
                </div>
                <Row className="blog-section-items">
                  {cat6ToShow &&
                    cat6ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat6.length > cat6ToShow.length && (
                  <a
                    onClick={() =>
                      setCat6CountToShow(
                        cat6CountToShow + allCat6.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
                
              </Container>}
              
              {hash=='blogs' && cat7ToShow && cat7ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>HR Compliance & Employment Law</h3>
                  <p class="intro">Stay on the right side of {locale.countryCodeLower == "nz" ? "New Zealand" : "Australian"} employment law with best practice tips from HR experts.</p>
                </div>
                <Row className="blog-section-items">
                  {cat7ToShow &&
                    cat7ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat7.length > cat7ToShow.length && (
                  <a
                    onClick={() =>
                      setCat7CountToShow(
                        cat7CountToShow + allCat7.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
                
              </Container>}
              
              {hash=='blogs' && cat8ToShow && cat8ToShow.length > 0 && 
              <Container>
                <div className="blog-section-heading">
                  <h3>The latest SME insights</h3>
                  <p class="intro">Keep your finger on the pulse of the people challenges faced by SMEs across {locale.countryCodeLower == "nz" ? "Aotearoa" : "Australia"}.</p>
                </div>
                <Row className="blog-section-items">
                  {cat8ToShow &&
                    cat8ToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {allCat8.length > cat8ToShow.length && (
                  <a
                    onClick={() =>
                      setCat8CountToShow(
                        cat8CountToShow + allCat8.length - ADD_RESOURCES_TO_SHOW
                      )
                    }
                  >Load more <FontAwesomeIcon icon={faPlus} /></a>)}
                </div>
                
              </Container>}
              
              {hash!='blogs' && itemsToShow && itemsToShow.length > 0 && 
              <Container>
                <Row className="blog-section-items">
                  {itemsToShow &&
                    itemsToShow.map((item) => (
                      <Col key={item.id} lg={4} md={6} className="mb-4">
                        <ResourceCard {...item} theme={theme} />
                      </Col>
                    ))}
                </Row>
                <div className="load-more-link">
                  {items.length > itemsToShow.length && (
                    <div className="mt-3">
                      <ViewMoreButton
                        onClick={() =>
                          setResourcesToShow(
                            resourcesToShow + ADD_RESOURCES_TO_SHOW + 6
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </Container>}
            
            </TabPane>
          )
        })}
      </TabContent>
    </div>
  )
}
