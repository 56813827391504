import React from 'react';
import { Container } from 'reactstrap';

import Section from './Section';

import './FaqSection.scss';

const FaqSection = (props) => {
  const { title, items } = props;
  return (
    <Section className="FaqSection isolated-section py-6">
      <Container>
        {title && <h2 className="mt-4 mb-5">{title}</h2>}

        {items &&
          <ul>
            {items.map((item, index) => (
              <li key={index}>
                <input type="checkbox" defaultChecked />
                <div className="faq-question">
                  <i/>
                  <h3>{item.question}</h3>
                </div>
                <div className="faq-answer">{item.answer}</div>
              </li>
            ))}
          </ul>
        }
      </Container>
    </Section>
  )
}

export default FaqSection