import React from 'react'
import classnames from 'classnames'
import {
  Card as RSCard,
  CardBody,
  CardTitle,
  CardSubtitle,
  Badge
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './Card.scss'


const Card = ({
  image = null,
  imageAlt = null,
  body = true,
  title = null,
  subtitle = null,
  text = null,
  video = null,
  onClick = null,
  link = null,
  isNew = false,
  category = null,
  className = '',
  theme = null,
  overlay = true,
  cta = null
}) =>
  <RSCard
    className={classnames({
      [`${className}`]: className,
      [`card-theme-${theme}`]: theme,
      'pb-0': !body
    })}
    onClick={onClick}
  >
    <div className="card-head">
      {image &&
        <img
          className="card-image"
          src={image}
          alt={imageAlt ? imageAlt : title}
        />
      }
      {overlay &&
        <div className="card-overlay"/>
      }
      {video &&
        <div className="card-play-icon">
          <FontAwesomeIcon icon={faPlay} className="icon play-icon" />
        </div>
      }
    </div>
    {body &&
      <CardBody>
        <div className="card-body-tags">
          {isNew &&
            <Badge color="radical-red" className="card-badge-new" pill>
              New
            </Badge>
          }
          {category && 
            <div className="card-category">
              {category}
            </div>
          }
        </div>
        {title &&
          <CardTitle>
            {category && 
              <div className="card-category-new">
                {category}
              </div>
            }

            {title}
          </CardTitle>
        }
        {subtitle &&
          <CardSubtitle>{subtitle}</CardSubtitle>
        }
        {text &&
          <div
            className="card-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        }
        {cta && 
          <a className="cta" href="javascript:;" onClick={onClick}>{cta} <FontAwesomeIcon icon={faArrowRight} /></a>
        }
      </CardBody>
    }
    {link &&
      <a
        href={link}
        className="card-link"
        aria-label={title}
      />
    }
  </RSCard>




export default Card