import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import LocaleContext from '../components/LocaleContext'
import{ sortResources } from '../components/Resources'

export const useLatestResources = () => {
  const locale = useContext(LocaleContext);

  const data = useStaticQuery(
    graphql`
      query LatestResourcesSectionQuery {
        allContentfulBlogPost(sort: {order: DESC, fields: created}) {
          edges {
            node {
              ...ContentfulBlogPostFragment
            }
          }
        }
        allContentfulGuide(sort: {order: DESC, fields: created}) {
          edges {
            node {
              ...ContentfulGuideFragment
            }
          }
        }
        allContentfulFacebookLive(sort: {order: DESC, fields: created})  {
          edges {
            node {
              ...ContentfulFacebookLiveFragment
            }
          } 
        }
      }
    `
  )

  let blogs = data.allContentfulBlogPost && data.allContentfulBlogPost.edges.map(({ node }) => ({ ...node, type: 'blog' }))
  blogs = blogs.filter(item => item.locale && item.locale.includes(locale.value))
  blogs = sortResources(blogs)

  let guides = data.allContentfulGuide && data.allContentfulGuide.edges.map(({ node }) => ({ ...node, type: 'guide' }))
  guides = guides.filter(item => item.locale && item.locale.includes(locale.value))
  guides = sortResources(guides)

  let lives = data.allContentfulFacebookLive && data.allContentfulFacebookLive.edges.map(({ node }) => ({ ...node, type: 'live' }))
  lives = lives.filter(item => item.locale && item.locale.includes(locale.value))
  lives = sortResources(lives)

  return {
    blogs,
    guides,
    lives
  }
}