import React from 'react'
import { graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

import Card from './Card'
import ModalVideo from './ModalVideo'

import './ResourceCard.scss'

/**
 * Live Card
 *
 */
const LiveCard = props => {
  const subtitle =
    props.author && props.author.name
      ? `By ${props.author.name} - ${props.created}`
      : props.created

  const type = <div><FontAwesomeIcon icon={faPlay} />{" Webinar"}</div>;

  const cta = "Watch webinar";

  return (
    <ModalVideo {...props.video}>
      {({ toggle }) => (
        <Card
          image={props.image && props.image.file && props.image.file.url}
          title={props.title}
          subtitle={subtitle}
          text={
            props.summary &&
            props.summary.childMarkdownRemark &&
            props.summary.childMarkdownRemark.html
          }
          video={true}
          isNew={props.isNew}
          category={type}
          cta={cta}
          theme={props.theme}
          onClick={toggle}
        />
      )}
    </ModalVideo>
  )
}

/**
 * Guide Card
 *
 */
const GuideCard = props => {
  const subtitle =
    props.author && props.author.name ? `By ${props.author.name}` : null

  const type = <div><FontAwesomeIcon icon={faDownload} />{" Guide"}</div>;

  const cta = "Download guide";

  return (
    <Card
      image={props.image && props.image.file && props.image.file.url}
      title={props.title}
      subtitle={subtitle}
      text={
        props.summary &&
        props.summary.childMarkdownRemark &&
        props.summary.childMarkdownRemark.html
      }
      isNew={props.isNew}
      link={props.link}
      category={type}
      cta={cta}
      theme={props.theme}
    />
  )
}

/**
 * Blog Card
 *
 */
const BlogCard = props => {
  const subtitle =
    props.author && props.author.name
      ? `By ${props.author.name} - ${props.created}`
      : props.created

  const type = <div><FontAwesomeIcon icon={faBook} />{" Blog"}</div>;

  const cta = "Read more";

  return (
    <Card
      image={props.image && props.image.file && props.image.file.url}
      title={props.title}
      subtitle={subtitle}
      text={
        props.summary &&
        props.summary.childMarkdownRemark &&
        props.summary.childMarkdownRemark.html
      }
      isNew={props.isNew}
      link={props.link}
      category={type}
      cta={cta}
      theme={props.theme}
    />
  )
}

/**
 * CTA Card
 *
 */
const CTACard = props => {
  return (
    <Card
      image={props.image && props.image.file && props.image.file.url}
      imageAlt={props.image && props.image.title}
      link={props.link}
      body={false}
      theme={props.theme}
      className="CTAResourceCard"
    />
  )
}

/**
 * Whitepaper Card
 *
 */
const WhitePaperCard = props => {
  const subtitle =
    props.author && props.author.name
      ? `By ${props.author.name} - ${props.created}`
      : props.created

  const type = <div><FontAwesomeIcon icon={faBook} />{" White Paper"}</div>;

  const cta = "Read more";

  return (
    <Card
      image={props.listingImage && props.listingImage.file && props.listingImage.file.url}
      title={props.title}
      text={props.summary && props.summary.childMarkdownRemark && props.summary.childMarkdownRemark.html}
      link={props.externalLink ? props.externalLink : `/${props.localePrefix}/white-paper/${props.slug}`}
      cta={cta}
      category={type}
    />
  )
}

const ResourceCard = props => {
  if (props.type === 'live' || props.type === 'ContentfulFacebookLive') return <LiveCard {...props} />

  if (props.type === 'guide' || props.type === 'ContentfulGuide') return <GuideCard {...props} />

  if (props.type === 'blog' || props.type === 'ContentfulBlogPost') return <BlogCard {...props} />
  
  if (props.type === 'whitepaper' || props.type === 'ContentfulWhitePaper') return <WhitePaperCard {...props} />

  if (props.type === 'cta') return <CTACard {...props} />

  return null
}

export default ResourceCard

export const resourcesFragment = graphql`
  fragment ContentfulGuideFragment on ContentfulGuide {
    __typename
    id
    title
    link
    locale
    image {
      title
      thumbnail: fixed(width: 70, height: 70) {
        src
      }
      file {
        url
      }
    }
    author {
      name
      position
    }
    isNew
    created(formatString: "DD MMM YYYY")
    summary {
      childMarkdownRemark {
        html
      }
    }
    order
  }
  fragment ContentfulFacebookLiveFragment on ContentfulFacebookLive {
    __typename
    id
    title
    slug
    locale
    video {
      ...Video
    }
    image {
      title
      thumbnail: fixed(width: 70, height: 70) {
        src
      }
      file {
        url
      }
    }
    author {
      name
      position
    }
    isNew
    created(formatString: "DD MMM YYYY")
    summary {
      childMarkdownRemark {
        html
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    order
  }
  fragment ContentfulLandingPageFragment on ContentfulLandingPage {
    __typename
    title
    timeDate
    slug
    bannerText {
      childMarkdownRemark {
        html
      }
    }
    mainText {
      childMarkdownRemark {
        html
      }
    }
    optionalBannerImage {
      file {
        url
      }
    }
    bannerImage {
      file {
        url
      }
    }
    formId
    className
    brandLogo {
      file {
        url
      }
    }
    brandText
    webinarHost
    hostImage2 {
      file {
        url
      }
    }
    hostText2
    hostPosition1
    hostPosition2
    hostImage3 {
      file {
        url
      }
    }
    hostName3
    hostPosition3
    hostImage4 {
      file {
        url
      }
    }
    hostName4
    hostPosition4
    hostDesc1 {
      json
    }
    hostDesc2 {
      json
    }
    hostDesc3 {
      json
    }
    hostDesc4 {
      json
    }
    localePrefix
    communityHubTitle
    communityHubDescription {
      childMarkdownRemark {
        html
      }
    }
    communityHubThumbnail {
      file {
        url
      }
    }
  }
  fragment ContentfulIntegrationPageFragment on ContentfulIntegrationPage {
    __typename
    title
    slug
    localePrefix
    bannerTitle
    bannerDescription {
      childMarkdownRemark {
        html
      }
    }
    bannerLogo {
      file {
        url
      }
    }
    bodyText {
      json
    }
    bodyImage {
      file {
        url
      }
    }
    integrationText {
      json
    }
    integrationLink
    integrationGraphic {
      file {
        url
      }
    }
    testimonial {
      whoName
      jobTitle
      companyName
      quote {
        childMarkdownRemark {
          html
        }
      }
      logo {
        file {
          url
        }
      }
    }
    freeTrialLink
    freeTrialText
    bullet1Text {
      json
    }
    bullet1Icon {
      file {
        url
      }
    }
    bullet2Text {
      json
    }
    bullet2Icon {
      file {
        url
      }
    }
    bullet3Text {
      json
    }
    bullet3Icon {
      file {
        url
      }
    }
    bullet4Text {
      json
    }
    bullet4Icon {
      file {
        url
      }
    }
  }
  fragment ContentfulPartnerPageFragment on ContentfulPartnerPage {
    __typename
    title
    slug
    localePrefix
    bannerDescription {
      childMarkdownRemark {
        html
      }
    }
    partnerLogo {
      file {
        url
      }
    }
    bodyText {
      json
    }
    bodyImage {
      file {
        url
      }
    }
    freeTrialLink
    freeTrialText
  }
  fragment ContentfulBlogPostFragment on ContentfulBlogPost {
    __typename
    id
    title
    link
    locale
    image {
      title
      thumbnail: fixed(width: 70, height: 70) {
        src
      }
      file {
        url
      }
    }
    author {
      name
      position
    }
    isNew
    created(formatString: "DD MMM YYYY")
    category
    tags
    summary {
      childMarkdownRemark {
        html
      }
    }
  }
  fragment ContentfulResourceCtaFragment on ContentfulResourceCta {
    __typename
    id
    link
    image {
      title
      file {
        url
      }
    }
    order
  }
  fragment ContentfulWhitePaperFragment2 on ContentfulWhitePaper {
    __typename
    id
    title
    slug
    localePrefix
    externalLink
    image {
      file {
        url
      }
    }
    listingImage {
      file {
        url
      }
    }
    summary {
      childMarkdownRemark {
        html
      }
    }
    intro {
      childMarkdownRemark {
        html
      }
    }
  }
`
