import React from 'react'
import { Container } from 'reactstrap'

import Section from './Section'
import Resources, { sortResources } from './ResourcesNew'
import SubscribeForm from './SubscribeForm'
import { useLatestResources } from '../hooks/latest-resources'

import './LatestResourcesSection.scss'


/**
 * Get all the resources
 * 
 */
const getListing = () => {
  let { blogs, guides, lives } =  useLatestResources()

  blogs = blogs.slice(0,3)
  guides = guides.slice(0,3)
  lives = lives.slice(0,3)

  let featured = sortResources([
    ...blogs.slice(0, 1),
    ...guides.slice(0, 1),
    ...lives.slice(0, 1),
  ])

  return {
    blogs,
    guides,
    lives,
    featured
  }
}


export default () => {
  const listing = getListing()
  return (
    <>
      <div id="featured"/>
      <div id="blogs"/>
      <div id="guides"/>
      <div id="live"/>
      <Section id="latest-resources" className="LatestResourcesSection isolated-section">
        <Container className="text-center mb-5">
          <h2>Latest HR Resources</h2>
        </Container>

        <Resources
          theme="light"
          navSticky={false}
          settings={[
            {
              label: 'Featured',
              hash: 'featured',
              items: listing.featured
            },
            {
              label: 'Blogs',
              hash: 'blogs',
              items: listing.blogs
            },
            {
              label: 'Guides',
              hash: 'guides',
              items: listing.guides
            },
            {
              label: 'Webinars',
              hash: 'live',
              items: listing.lives
            },
          ]}
        />

        <Container className="mb-3">
          <div className="LatestResourcesSection__subscribe">
            <h3 className="h4 text-center mb-4 pb-2">Get the latest resources straight to your inbox!</h3>
            <SubscribeForm
              themeColor="white"
              textColor="white"
            />
          </div>
        </Container>
      </Section>
    </>
  )
}